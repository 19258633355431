<template>
  <p>Выход</p>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'Logout',
  created() {
      this.$auth.logout({});
  },
});
</script>